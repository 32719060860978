@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Red Hat Display', sans-serif;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.maintitle {
  font-size: 4rem;
}

h1 {
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  color: black;
}

h1 span {
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  color: black;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  /* font-size: 3rem; */
  text-align: center;
  color: black;
}

h2 span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: black;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: black;
}

h3 span {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: black;
}

h4 {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  color: black;
  text-align: justify;
}

h4 span {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  color: black;
}

.center {
  justify-content: center;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.home {
  padding-bottom: 2rem;
}

.NavBar {
  padding-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.card {
  margin-top: 27px;
  border-radius: 50%;
}

.card:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.contact {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* .ant-drawer-body {
  background-color: #000000;
} */

/* :where(.css-dev-only-do-not-override-gzal6t).ant-drawer .ant-drawer-title {
  color: #fff;
} */

.linkedin:hover {
  transform: scale(1.05);
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06); */
  cursor: pointer;
}

.ant-card-head-title {
  color: #0087ff;
  padding-bottom: 0.5rem;
  padding-top: 1.6rem;
}

.ant-collapse-header-text {
  color: #000000;
}

li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.navbar {
  align-items: flex-start;
  height: 7.45vh;
}

.ant-collapse-header-text {
  font-size: 1.2rem;
  font-weight: 500;
}

.split-container {
  display: flex;
  z-index: inherit;
}

.only-section {
  flex-grow: initial; /* Adjust for desired split ratio */
  overflow-y: scroll;
  height: 93vh; /* Adjust as needed */
  width: 100vw;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.only-section-career {
  flex-grow: initial; /* Adjust for desired split ratio */
  overflow-y: scroll;
  height: 93vh; /* Adjust as needed */
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.left-section {
  flex-grow: initial; /* Adjust for desired split ratio */
  overflow-y: scroll;
  height: 92vh; /* Adjust as needed */
  width: 70vw;
  padding-left: 2rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.right-section {
  flex-grow: inherit; /* Adjust for desired split ratio */
  overflow-y: scroll;
  height: 92vh; /* Adjust as needed */
  width: 30vw;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

/* ul {
  list-style-image: url('./images/bulletpoint.webp');
} */

ul h4 {
  color: black;
}

.list {
  margin: 0;
  padding: 18px 0 10px 50px;
  list-style: none;
  background-image: url('./images/bulletpoint-icon.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 25px;
}

.list-2 {
  margin: 0;
  padding: 13px 0 13px 40px;
  list-style: none;
  background-image: url('./images/bulletpoint-icon.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 20px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.job {
  padding-top: 1.2rem;
  padding-bottom: 1rem;
}

.job-list {
  font-size: 2vh;
}

#sitemap {
  background-color: white;
}

#contact {
  background-color: black;
}

#contact svg {
  color: white;
}

#contact div.ant-drawer-title {
  color: white;
}

#values {
  background-color: black;
}

#values svg {
  color: white;
}

#values div.ant-drawer-title {
  color: white;
}

#offerings h4 {
  font-size: 19px;
  text-align: start;
}

#benefits h4 {
  text-align: center;
  margin-bottom: 0;
}

/* Desktop */
@media (min-width: 1025px) {
  .mobile {
    display: none;
  }

  .tablet {
    display: none;
  }

  .career-values {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .jobs {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .testimonial {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 2rem;
  }

  .testimonial-text {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* Tablet */
@media (max-width: 1024px) and (min-width: 768px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: none;
  }
  .tablet-home {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  html,
  body {
    margin: auto;
    height: 100%;
    overflow: auto;
  }
  .right-section {
    height: 0vh;
    display: none;
  }
  .left-section {
    height: 90vh;
    width: 100vw;
    padding-right: 1rem;
    padding-left: 1rem;
    z-index: 1;
  }
  .career-values {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .testimonial {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .tablet {
    display: none;
  }

  .desktop {
    display: none;
  }

  .maintitle {
    font-size: 3rem;
  }

  .left-section {
    height: 90vh;
    width: 100vw;
    padding-right: 1rem;
    padding-left: 1rem;
    z-index: 1;
  }
  .right-section {
    height: 0vh;
    display: none;
  }

  .career-values {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  html,
  body {
    margin: auto;
    height: 100%;
    overflow: auto;
  }

  .desktop {
    display: none;
  }

  .testimonial {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
  }

  .testimonial-text {
    padding-left: 1px;
    padding-right: 1px;
  }

  .jobs {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#who-are-you-first-text {
  color: #bc13fe;
}

.css-1pnmrwp-MuiTypography-root {
  color: #fff !important;
}

.arrow-icon-to-animate.rotated {
  transform: rotate(180deg);
}
